/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers', 'local'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Cagip',
        clientId: 'vi-cagip',
        buttonText: 'Accès CA-GIP',
      },
    ],
  ],
  authLocalOptions: {
    mode: 'otp',
  },
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '64c114cf5d6a3452971f70fc',
          i18n: {
            fr: {
              title: 'Stratégie CA-GIP',
              description: `Vos podcasts dédiés à la stratégie de CA-GIP`,
            },
          },
          heading: {
            color: '#00003E',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '64c112485d6a3455a01f707e',
          i18n: {
            fr: {
              title: 'Actualités Tech',
              description: `Les actualités tech de CA-GIP et de son environnement`,
            },
          },
          heading: {
            color: '#06c597',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '64c114e55d6a34a6d41f7102',
          i18n: {
            fr: {
              title: 'RSE le podcast',
              description: `Ensemble pour un numérique responsable`,
            },
          },
          heading: {
            color: '#99cc00',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '61c5ccae3c61ef314357c666',
          i18n: {
            fr: {
              title: 'Veille Cyber',
              description: `L’essentiel de la veille cyber en 5’ proposé par les équipes Risques IT CA-GIP et Veille Cyber`,
            },
          },
          heading: {
            color: '#65247D',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '64c115485d6a34f4ba1f7129',
          i18n: {
            fr: {
              title: '3 minutes avec',
              description: `À la rencontre des équipes de CA-GIP, de leurs métiers et de leurs projets`,
            },
          },
          heading: {
            color: '#009597',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '6707e2f2e6e7d7140bb30229',
          i18n: {
            fr: {
              title: 'Paroles de clients',
              description: `Histoires applicatives et retours d'expériences sur l’adoption des plateformes CA-GIP`,
            },
          },
          heading: {
            color: '#006A4E',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '64c115635d6a3480a61f713a',
          i18n: {
            fr: {
              title: 'In&Out',
              description: `Accompagner les experts et non experts dans la compréhension des transformations en cours`,
            },
          },
          heading: {
            color: '#146350',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        /**
        {
          type: 'tag',
          tagId: '64c1156c5d6a34dd781f7140',
          i18n: {
            fr: {
              title: 'Développement personnel',
              description: `Manager les transformations, l'essentiel de la formation`,
            },
          },
          heading: {
            color: '#d70365',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        */
        /** 
        {
          type: 'tag',
          tagId: '6566fb11842dabdbfb6b2721',
          i18n: {
            fr: {
              title: 'Second souffle',
              description: `Une sélection de contenus inspirants portés par des intervenants variés`,
            },
          },
          filters: {
            type: 'solo',
            soloTagId: '6566fc92842dab04a96b274e',
          },
          heading: {
            color: '#FBBA00',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        */
      ],
    },
  ],
}
