<template>
  <div class="w-block__footer">
    <!-- footer block -->
    <div class="mt-1 mb-4">
      <img
        :src="require('~/assets/images/logo-cagip.png')"
        height="60"
        alt="logo-deezer"
        class="mr-4"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
