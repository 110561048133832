var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-vinci-form-modal': true,
      'w-vinci-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-close-thick")])],1)],1),_vm._v(" "),_c('v-img',{attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/popupmobileenquete.jpg')
          : require('~/assets/images/popupdesktopenquete.jpg'),"alt":"image popup","max-height":_vm.$voicer.isMobile ? 368 : undefined},on:{"click":function($event){return _vm.handleOpen('https://forms.office.com/e/ZHHRXQZA4J')}}}),_vm._v(" "),_c('v-card-text',{staticClass:"w-vinci-form-modal__body2",class:{
        'pa-2 subtitle-2': _vm.$voicer.isMobile === false,
        'pa-1 subtitle-2': _vm.$voicer.isMobile === true,
      }},[_c('div',{staticClass:"body-2"},[_vm._v("\n        Dans le cadre du traitement des réponses à ce questionnaire, aucune\n        donnée à caractère personnel ne sera traitée\n        "),_c('br')]),_vm._v(" "),_c('div',{staticClass:"caption"},[_c('a',{attrs:{"href":"https://www.radio-ca-gip.fr/data/personal-data"}},[_vm._v("Vous pouvez consulter notre politique de données personnelles")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }