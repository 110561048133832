<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-sheet color="primary" dark class="pa-3">
        <div class="subtitle-1">
          Bienvenue sur Radio CA-GIP ! Au programme : des podcasts thématiques,
          à écouter et réécouter sans limite !
          <a href="https://www.radio-ca-gip.fr/faq"
            >Pour en savoir plus, consultez la FAQ</a
          >
        </div>
      </v-sheet>
    </v-container>

    <w-block name="cta" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-sheet__desktop {
  & > div {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

a {
  color: #ffff;
  text-decoration: underline;
}

.w-block__pre-heading {
  justify-content: center;
  text-align: center;
}
.v-btn {
  border-radius: 4px;
}

.v-sheet {
  border-radius: 8px;
}
</style>
